import { useCallback, useEffect, useState } from 'react'
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { Container, Flex, Heading, Image, Text } from '@chakra-ui/react'

import { getUser } from '../graphql/queries'

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

const Profile = () => {
  const [avatar, setAvatar] = useState('');
  const { user } = useAuthenticator((context) => [context.user]);

  const fetchAvatar = useCallback(async () => {
    try {
      // check if the user already has a profile picture saved
      const { data: { getUser: { image } } } = await API.graphql(graphqlOperation(getUser, { id: user.username }))
      console.log('image: ', image)
      setAvatar(image)
    } catch (error) {
      console.log('no image: ', error)
    }
  }, [user.username])

  useEffect(() => {
    fetchAvatar()
  }, [fetchAvatar])

  return (
    <Container>
      <Heading as='h2' my={6} size='xl'>Dein Profile</Heading>
      <Flex flexDirection="column" alignItems="center">
        {!!avatar && <Image alt="Profile Picture" borderRadius="50%" height="92px" mb="16px" src={avatar} width="92px"  />}
          <Text fontWeight="bold" mb={2}>{user.attributes.name}</Text>
          <Text>{user.attributes.email}</Text>
      </Flex>
    </Container>
  )
}

export default Profile;
