import { Box } from '@chakra-ui/react'

const PostWrapper = ({ children, id, type }) => {
  return (
    <Box
      bg="white"
      borderRadius="24px"
      key={id}
      p="16px"
      position="relative"
      {...(type === 'question' ? { 
        css : 
          `
            &::before {
              content: '???';
              position: absolute;
              font-size: 64px;
              font-weight: 900;
              color: #F3F9FA;
              right: 16px;
              top: -8px;
            }
          `
      } : {})}
    >
      {children}
    </Box>
  )
}

export default PostWrapper