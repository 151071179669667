import { Heading } from '@chakra-ui/react'

const FutureHeading = () => (
  <Heading
    as='h2'
    color="white"
    maxWidth="260px"
    mb={6}
    size='xl'
    _before={{
      content: '""',
      display: 'block',
      height: '6px',
      width: '109px',
      bg: 'white',
      mb: '8px',
    }}
  >
    Schnuppertag IT @Jobcloud 2023
  </Heading>
)

export default FutureHeading
