import { useState } from 'react'
import { useNavigate } from 'react-router';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { v4 as uuid } from 'uuid'

import { Button, Flex, Input, FormControl, FormLabel, Stack, Text, Textarea } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

import { createPost } from '../graphql/mutations'

const CommentForm = () => {
  const [comment, setComment] = useState('')
  const [error, setError] = useState('')
  const [filename, setFilename] = useState('');
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  function hashFileName(e) {
    if (!e.target.files[0]) return
    
    const file = e.target.files[0]

    // Make sure the file name is unique
    const filename = uuid() + file.name.replace(/\s/g, '-').toLowerCase()
    console.log('filename: ', filename)
    return { file, filename };
  }

  async function addPicture(e) {
    const { file, filename } = hashFileName(e)
    
    try {
      await Storage.put(filename, file)
      setFilename(filename)
    } catch (error) {
      console.log('error addPicture: ', error)
    }
  }

  async function addComment() {
    try {
      if (!comment) {
        setError('Es fehlt ein Kommentar!')
        return
      } else if (!filename) {
        await API.graphql(graphqlOperation(createPost, {input: { message: comment, type: 'comment', userID: user.username }}))
      } else {
        await API.graphql(graphqlOperation(createPost, {input: { message: comment, image: filename, type: 'comment', userID: user.username }}))
      }
      setComment('')
      navigate('/board');
    } catch (err) {
      console.log('error creating comment:', err)
    }
  }

  return (
    <>
      <Stack spacing={4} mt={8}>
        <FormControl>
          <Button leftIcon={<AddIcon />} mt={2} sx={{ ':hover': { bg: '#CCE6EB', color: '#004E5C' }}} variant="ghost"><FormLabel htmlFor="add-image" m={0} sx={{ ':hover': { cursor: 'pointer' }}}>Bild  hinzufügen</FormLabel></Button>
          <Input display="none" focusBorderColor='teal.600' id="add-image" onChange={addPicture} size='lg' type="file" />
          {!!filename && <Text>{filename}</Text>}
        </FormControl>
        <Textarea bg="white" borderColor="teal.200" focusBorderColor='teal.600' id="comment" onChange={e => { setComment(e.target.value); setError(''); }} placeholder='Kommentar' _placeholder={{ color: 'blackAlpha.400' }} rows={7} size="lg" value={comment} />
        {!!error && <Text color="red.500">{error}</Text>}
      </Stack>
      <Flex flexDirection={['column-reverse', 'row']} justifyContent={['flex-start', 'flex-end']} gap="16px" mt={8} >
        <Button borderRadius="24px" onClick={() => navigate('/board')} width={['100%', 'auto']} variant="ghost" _hover={{ bg: 'teal.100', color: 'teal.700' }}>Abbrechen</Button>
        <Button onClick={addComment} px="56px" width={['100%', 'auto']} _hover={{ bg: 'teal.500' }}>Beitrag posten</Button>
      </Flex>
    </>
  )
}

export default CommentForm;
