import { useState } from 'react'
import { useNavigate } from 'react-router';
import { API, graphqlOperation } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { Button, Flex, Stack, Text, Textarea } from '@chakra-ui/react'

import { createPost } from '../graphql/mutations'

const InterviewForm = () => {
  const [question, setQuestion] = useState('')
  const [error, setError] = useState('')
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  console.log('question: ', question)

  async function askQuestion() {
    try {
      if (!question) {
        setError('Es fehlt eine Frage!')
        return
      }
      await API.graphql(graphqlOperation(createPost, {input: { message: question, type: 'question', userID: user.username }}))
      setQuestion('')
      navigate('/board');
    } catch (err) {
      console.log('error creating comment:', err)
    }
  }

  return (
    <>
      <Stack spacing={4} mt={8}>
        <Textarea bg="white" borderColor="teal.200" focusBorderColor='teal.600' name="question" onChange={e => { setQuestion(e.target.value); setError(''); }} placeholder='Stelle deine Frage hier' _placeholder={{ color: 'blackAlpha.400' }} rows={4} size="lg" value={question} />
        {!!error && <Text color="red.500">{error}</Text>}
      </Stack>
      <Flex flexDirection={['column-reverse', 'row']} gap="16px" mt={8} justifyContent={['flex-start', 'flex-end']}>
        <Button borderRadius="24px" onClick={() => navigate('/board')} width={['100%', 'auto']} variant="ghost" _hover={{ bg: 'teal.100', color: 'teal.700' }}>Abbrechen</Button>
        <Button onClick={askQuestion} px="56px" width={['100%', 'auto']} _hover={{ bg: 'teal.500' }}>Beitrag posten</Button>
      </Flex>
    </>
  )
}

export default InterviewForm;