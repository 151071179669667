import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'

const NavMenu = () => {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
  ]);
  const navigate = useNavigate();

  const logOut = () => {
    signOut();
    navigate('/login')
  }
  
  return (
    <Menu>
      <MenuButton as={IconButton} icon={<HamburgerIcon />} variant='ghost' />
      <MenuList>
      {route !== 'authenticated' ? null : (
        <>
          <MenuItem onClick={() => navigate('/profile')}>Profil</MenuItem>
          <MenuDivider />
        </>
      )}
        <MenuItem onClick={() => navigate('/')}>Startseite</MenuItem>
        <MenuItem onClick={() => navigate('/board')}>Board</MenuItem>
        <MenuItem onClick={() => navigate('/create-a-post')}>Beitrag posten</MenuItem>
        <MenuDivider />
        {route !== 'authenticated' ? <MenuItem onClick={() => navigate('/login')}>Login</MenuItem> : <MenuItem onClick={() => logOut()}>Logout</MenuItem>}
      </MenuList>
    </Menu>
  )
}

export default NavMenu;