import { useNavigate } from 'react-router';
import { IconButton } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'

const CreatePostButton = () => {
  const navigate = useNavigate();

  return (
    <IconButton
      bg="#A977B1"
      borderRadius="50%"
      bottom="16px"
      height="64px"
      icon={<MdAdd h="24px" w="24px" />}
      onClick={() => navigate('/create-a-post')}
      position="fixed"
      right="16px"
      width="64px"
      sx={{
        '&:hover, &:active': {
          backgroundColor: '#76447E',
          cursor: 'pointer',
        },
        'svg': {
          height: '24px',
          width: '24px',
        }
      }}
      zIndex="1"
    />
  )
}

export default CreatePostButton