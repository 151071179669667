import { Global, css } from '@emotion/react';

const BoardBackground = () => (
  <Global styles={css` 
    body {
      background-color: #339BAE;
      background-image: url('board-bg-mobile.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }

    @media (min-width: 768px) {
      body {
        background-image: url('board-bg-desktop.svg');
      }
    }
    
    nav > button {
      color: white !important;
    }
    nav > button:hover svg, {
      color: #339BAE !important;
    }
    
    `}
  />
)

export default BoardBackground;
