import { Button, Menu, MenuButton, MenuItem, MenuGroup, MenuList } from '@chakra-ui/react'
import { MdTune } from 'react-icons/md'

const FilterMenu = ({ listAllPosts, listComments, listInterviews, listUserPosts }) => {
  

  return (
    <Menu>
      <MenuButton as={Button} color="white" colorScheme='teal' leftIcon={<MdTune />} variant='ghost' _hover={{ bg: 'white', color: '#339BAE' }} _expanded={{ color: 'teal.700' }}>
        Filter
      </MenuButton>
      <MenuList>
        <MenuGroup title='Anzeigen'>
          <MenuItem onClick={listAllPosts}>Alle Beiträge</MenuItem>
          <MenuItem onClick={listUserPosts}>Meine Beiträge </MenuItem>
          <MenuItem onClick={listInterviews}>Nur Interviews</MenuItem>
          <MenuItem onClick={listComments}>Nur Kommentare</MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

export default FilterMenu;