import React from 'react';
import { Outlet } from 'react-router-dom';

import { Flex, Grid, GridItem, Link, Text } from '@chakra-ui/react';

import NavMenu from '../ui/NavMenu'

const Layout = () => (
  <Grid minHeight="100vh" templateRows="auto 1fr auto">
    <GridItem as="header">
      <Flex as="nav" justifyContent="flex-end" p={4}>
        <NavMenu />
      </Flex>
    </GridItem>

    <GridItem>
      <Outlet />
    </GridItem>

    <GridItem
      as="footer"
      justifyContent="center"
      margin="32px auto 0"
      position="relative"
      px={8}
      py={6}
    >
      <Text><Link colorScheme="teal" href='https://www.jobcloud.ch/c/de-ch/' isExternal variant="primary">© 2023 JobCloud AG</Link></Text>
    </GridItem>
  </Grid>
);

export default Layout;
