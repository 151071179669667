import { useEffect } from 'react';
import { I18n } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { Button, Heading, Text, translations, useAuthenticator, View } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router';

import { Box } from '@chakra-ui/react'

import '@aws-amplify/ui-react/styles.css';

const Login = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();

  I18n.putVocabularies(translations);
  I18n.setLanguage('de');

  useEffect(() => {
    if (route === 'authenticated') {
      navigate('/board');
    }
  }, [route, navigate]);

  const components = {
    Header() {
      return (
        <View textAlign="center" padding="32px">
          <Heading level={5}>Du musst angemeldet sein, um die Website nutzen zu können.</Heading>
        </View>
      );
    },
    SignIn: {
      Footer() {
        const { toResetPassword } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              marginBottom="26px"
              size="small"
              variation="link"
            >
              Passwort vergessen?
            </Button>
          </View>
        );
      },
    },

    SignUp: {
      Header() {
        return (
          <Text padding="32px 0 0 32px">
            Nur VIP's wie du können heute einen Account erstellen. Damit wir deine Daten sichern können musst du deine E-mail Adresse während der Anmeldung validieren.
          </Text>
        );
      }
    },

    ResetPassword: {
      Header() {
        return (
          <Heading
            paddingTop="32px"
            level={3}
          >
            Passwort zurücksetzen
          </Heading>
        );
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        placeholder: 'E-Mail-Adresse'
      },
      password: {
        placeholder: 'Passwort',
      },
    },
    signUp: {
      name: {
        order:1
      },
      email: {
        order: 2,
        placeholder: 'E-Mail-Adresse',
      },
      password: {
        order: 3,
        placeholder: 'Passwort',
      },
      confirm_password: {
        order: 4,
        placeholder: 'Passwort bestätigen',
      },
    },
   }

  return (
    <Box
      sx={{
        '[data-amplify-router]': {
          borderWidth: 0,
          boxShadow: 'none',
        },
        '.amplify-input': {
          borderColor: 'teal.200',
          borderRadius: '8px',
          color: 'rgba(0, 0, 0, 0.4)',
          height: '48px',
          ':focus': {
            boxShadow: 'none',
            borderColor: 'teal.400',
          }
        },
        '.amplify-field-group__outer-end .amplify-field-group__control:not(:focus)': {
          borderColor: 'teal.200',
          borderLeft: 'none',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
        },
        '.amplify-button--primary': {
          backgroundColor: 'teal.500',
          borderRadius: '24px',
          height: '48px',
          ':hover': {
            backgroundColor: 'teal.600',
          }
        }
      }}
    >
      <Authenticator components={components} formFields={formFields} />
    </Box>
  );
}

export default Login;
