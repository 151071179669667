import { Helmet } from 'react-helmet-async';
import { Authenticator } from '@aws-amplify/ui-react';

import Board from './components/Board';
import CreatePost from './components/CreatePost';
import Home from './components/Home';
import Layout from './components/Layout';
import Login from './components/Login';
import Profile from './components/Profile';
import RequireAuth from './utils/RequireAuth';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

function MyRoutes() {
  return (
    <>
      <Helmet>
        <title>Schnuppertag IT 2023 @Jobcloud AG</title>
        <meta name='description' content='Nimm am Future Day 2022 teil und entdecke die Berufe der Zukunft.' />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route
              path="/create-a-post"
              element={
                <RequireAuth>
                  <CreatePost />
                </RequireAuth>
              }
            />
            <Route
              path="/board"
              element={
                <RequireAuth>
                  <Board />
                </RequireAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;
