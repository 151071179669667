import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Global, css } from '@emotion/react';

import { Box, Button, Container, Flex, Heading, Image, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons'

const Home = () => {
  const { route } = useAuthenticator((context) => [
    context.route,
  ]);
  const navigate = useNavigate();

  return (
    <>
      <Global styles={css`
        body {
          background-color: #F3F9FA;
          background-image: url('home-bg-mobile.svg');
          background-repeat: no-repeat;
          background-size: 100% 700px;
        }

        @media (min-width: 768px) {
          body {
            background-image: url('home-bg-desktop.svg');
            background-size: 750px;
          }
        }
        
        `}
      />
      <Container display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} maxW={['100%', '100%', '70%', '70%', '80%']} pt={['192px']}>
        <Flex alignItems={['center', 'center', 'flex-start']} flexDirection="column">
          <Box width="420px" maxW={['250px', '250px', '400px']}>
            <Heading
              as='h1'
              color="white"
              mb={6}
              size={['xl', 'xl', '2xl']}
              _before={{
                content: '""',
                display: 'block',
                height: '6px',
                width: '109px',
                bg: 'white',
                mb: '8px',
              }}
            >
              Schnuppertag IT @Jobcloud 2023
            </Heading>
            <Button
              bg="white"
              color="teal.600"
              onClick={() => {route !== 'authenticated' ? navigate('/login') : navigate('/create-a-post')}}
              px="64px"
              _hover={{ bg: 'teal.100', color: 'teal.800' }}
            >
              Beitrag schreiben
            </Button>
          </Box>
        </Flex>
        <Box maxWidth="420px" ml={['auto', 'auto', '0', '0', '400px']} mr={['auto', 'auto', '0']} mt={['270px', '270px', '350px', '350px', '0']}>
          <Heading as='h2' mb="24px" size='xl'>
            Darum geht's
          </Heading>
          <Text pb="32px">An diesem Tag öffnet die JobCloud AG für 8 junge Talente die Türen. Damit ermöglichen wir ihnen Erfahrung im Bereich der digitalen Produktentwicklung zu sammeln und können zeigen, wesshalb unsere Jobs Zukunft haben.</Text>
          <Heading as='h2' my="24px" size='xl'>
            Arbeitsbereiche
          </Heading>
          <UnorderedList mb="32px">
            <ListItem mb="8px">IT Hardware & Software</ListItem>
            <ListItem mb="8px">User Experience Design & Research</ListItem>
            <ListItem mb="8px">Front/Backend Entwicklung sowie Cloud Engineering</ListItem>
          </UnorderedList>
          <Image pb="32px" src="lernender.jpg" />
          <Heading as='h2' my="24px" size='xl'>
            Über uns
          </Heading>
          <Text mb="32px">Diese Website ist für uns in Zusammenarbeit mit den Teilnehmern des Future-day Programm erarbeitet worden und somit vertraulich. Weitere Informationen zu uns findest du auf <Link colorScheme="teal" href='https://www.jobcloud.ch/c/de-ch/' isExternal variant="primary">jobcloud.ch <ExternalLinkIcon mx='2px' /></Link></Text>
        </Box>
      </Container>
    </>
  );
}

export default Home;
