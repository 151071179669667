import CommentForm from './CommentForm'
import InterviewForm from './InterviewForm'

import { Container, Flex, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text } from '@chakra-ui/react'

const CreatePost = () => (
  <Container>
    <Heading as='h2' my={6} size='xl'>Beitrag schreiben</Heading>
    <Text mb={6}><Text as="span" fontWeight="bold">Interview:</Text> Stelle deine Frage und einer unserer JobCloud Mitarbeiter wird sie dir beantworten</Text>
    <Text mb={6}><Text as="span" fontWeight="bold">Kommentar:</Text> Teile uns deine Erfahrung während dem future day mit einem Kommentar, einem Bild, oder beidem, mit.</Text>
    <Flex justifyContent="center">
      <Tabs colorScheme="teal" defaultIndex={1} isFitted size="lg" variant='colorful' width="100%">
        <TabList>
          <Tab borderTopRightRadius="0">Interview</Tab>
          <Tab borderTopLeftRadius="0">Kommentar</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <InterviewForm />
          </TabPanel>
          <TabPanel>
            <CommentForm />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  </Container>
);

export default CreatePost;
