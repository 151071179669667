import { Box, Flex, Image, Text } from '@chakra-ui/react'

const User = ({ message, sm, user }) => (
  <Flex alignItems={sm ? 'flex-start' : 'center'} gap="16px" mb="24px" position="relative" _last={sm ? { mb: 0 } : {}}>
    <Image alt="Profile Picture" border="1px solid" borderColor="teal.300" borderRadius="50%" height={sm ? '32px' : '64px'} src={user?.image} width={sm ? '32px' : '64px'}  />
    <Box>
      <Text fontWeight="bold" {...(sm ? { fontSize: '12px' } : {})}>{user?.name}</Text>
      <Text {...(sm ? { fontSize: '12px', mb: '8px' } : {})}>{user?.role || 'Student'}</Text>
      {message && <Text>{message}</Text>}
    </Box>
  </Flex>
)

export default User;