import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Amplify } from 'aws-amplify';
import { ChakraProvider } from '@chakra-ui/react'

// Config
import awsExports from './aws-exports';

// Components
import App from './App';
import theme from './ui/theme'

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </HelmetProvider>
);
