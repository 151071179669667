import { useEffect, useState } from 'react'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { Box, Button, Container, Divider, Flex, Grid, Image, Text, Textarea } from '@chakra-ui/react'
import { useAuthenticator } from '@aws-amplify/ui-react';

import BoardBackground from '../ui/BoardBackground';
import CreatePostButton from '../ui/CreatePostButton';
import FilterMenu from '../ui/FilterMenu';
import FutureHeading from '../ui/FutureHeading';
import PostWrapper from '../ui/PostWrapper';
import User from '../ui/User';


import { listPosts } from '../graphql/queries'
import { createAnswer } from '../graphql/mutations'

const Board = () => {
  const [ comments, setComments ] = useState([])
  const [ filteredComments, setFilteredComments ] = useState([]);
  const [ answer, setAnswer ] = useState('')
  const [ admins, setAdmins ] = useState([])
  const [ showAnswerBox, setShowAnswerBox ] = useState()

  const { user } = useAuthenticator((context) => [context.user]);

  console.log('results: ', filteredComments)

  useEffect(() => {
    loadComments()
    isAdmin()
  }, [])

  async function isAdmin() {
    try {
      // Check if user belongs to admins group
      const admins = await user.signInUserSession?.accessToken?.payload["cognito:groups"];
      setAdmins(admins)
    } catch(error) {
      console.log("sorry but you're not allowed to answer post")
    }
  }

  async function loadComments() {
    try {
      const postData = await API.graphql(graphqlOperation(listPosts, { query: listPosts }))
      const posts = await Promise.all(postData.data.listPosts.items.map(async post => {
        const image = await Storage.get(post.image)
        post.image = image
        return post
      }))
      setComments(posts)
      setFilteredComments(posts)
    } catch(err) {
      console.log("Error loading comments: ", err)
    }
  }

  const listAllPosts = () => {
    setFilteredComments(comments)
  }

  const listUserPosts = () => {
    const userPosts = comments.filter(({ author }) => author.id === user.username)
    setFilteredComments(userPosts)
  }

  const listInterviews = () => {
    const interviews = comments.filter(({ type }) => type === 'question')
    setFilteredComments(interviews)
  }

  const listComments = () => {
    const interviews = comments.filter(({ type }) => type === 'comment')
    setFilteredComments(interviews)
  }

  const toggleAnswerBox = () => {
    setShowAnswerBox()
  }

  async function addAnswer(index) {
    console.log('index: ', index)
    console.log('user info: ', user.username)
    try {
      await API.graphql({
        query: createAnswer,
        variables: { input: { postID: index, userID: user.username, answer }},
        authMode: "AMAZON_COGNITO_USER_POOLS"
      })
      console.log('Do I get there?')
      setAnswer('')
      toggleAnswerBox()
    } catch(error) {
      console.log("Unable to create answer: ", error)
    }
  }

  return (
    <>
      <BoardBackground />
      <Container maxW='1000px'>
        <FutureHeading />
        <Flex direction='row-reverse' mb="32px">
          <FilterMenu comments={comments} listAllPosts={listAllPosts} listComments={listComments} listInterviews={listInterviews} listUserPosts={listUserPosts} user={user} />
        </Flex>
        <Grid gap="32px" templateColumns={["minmax(0, 1fr)", "minmax(0, 1fr)", "repeat(2, minmax(0, 1fr))"]}>
          {!!filteredComments && filteredComments?.map(({ answers, author, id, image, message, type}) => (
            <PostWrapper id={id} type={type}>
              <User user={author} />
              <Text>{message}</Text>
              {!(image.includes('null')) && <Image alt="" src={image} mt="8px" width="100%" />}
              {type === 'question' && 
                admins?.includes('admins') && (
                  <>
                    <Divider borderColor="teal.600" my="16px" />
                    <Button
                      bg="teal.50"
                      color="teal.600"
                      onClick={() => {
                          if (showAnswerBox === id) {
                            setShowAnswerBox();
                          } else {
                            setShowAnswerBox(id);
                          }
                        }
                      }
                      width="100%"
                      css={`
                        &:hover {
                          background-color: #CCE6EB;
                          color: #004E5C;
                        }
                      `}
                    >
                      Antworten
                    </Button>
                    {showAnswerBox === id && (
                      <Flex flexDirection="column">
                        <Textarea bg="white" borderColor="teal.200" focusBorderColor='teal.600' id="answer" mt="16px" onChange={e => setAnswer(e.target.value)} placeholder='Antwort' _placeholder={{ color: 'blackAlpha.400' }} rows={7} size="lg" value={answer} />
                        <Button alignSelf="flex-end" mt="16px" onClick={() => addAnswer(id)} px="56px" _hover={{ bg: 'teal.500' }}>Senden</Button>
                      </Flex>
                    )}
                  </>
                )
              }
              {!!answers && answers?.items.map(({answer, author, id}) => (
                <Box key={id} mt="16px">
                  <User message={answer} sm user={author} />
                </Box>
              ))}
            </PostWrapper>
          ))}
        </Grid>
        <CreatePostButton />
      </Container>
    </>
  )
}

export default Board;
